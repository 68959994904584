<template>
  <div>
    <div class="===========================----=MODAL TRANSAKSI BARU START=----============================="></div>
    <button @click="aktifModalTransaksi" 
    v-if="pilih=='adminapotek'"
     type="button" class="btn btn-sm btn-style9 rounded-circle" 
    style="position:fixed;bottom:55px;right:20px;z-index:100;height:40px;width:40px;">+</button>
    <div v-if="modaltransaksi" @click="offModalTransaksi" style="position:fixed;width:100vw;height:100vh;left:0;top:0;z-index:100;"></div>
    <div v-if="modaltransaksi" style="position: fixed;top: 50%;left: 50%;transform: translateX(-50%) translateY(-50%);z-index:105;min-width:50vw;">
      <div class="rounded-lg shadow p-3 bg-white animate__animated animate__bounceIn">
          <form action="" @submit.prevent="tambahTransaksiBaru">
            <span class="font-semibold italic">
              {{vdata.nota}}
            </span>
            <div class="p-2">
              <button type="button"  class="btn btn-sm btn-danger " @click="offModalTransaksi">x</button>
              <button type="button" class="btn btn-sm btn-dark  float-right" @click="$router.push('/admin/pelanggan')"><span class="typcn typcn-user-add"></span> Tambah Data Pasien</button>
            </div>
              <div class="table-responsive">
                  <table class="table table-sm table-striped table-bordered">
                      <tr>        
                          <td class="capitalize">Pasien</td>
                          <td class="pl-2 pr-2">:</td>
                          <td>
                            <v-select :options="pelanggans" label="item_data" v-model="vdata.id_pelanggan" :reduce="e => e.id"></v-select>
                          </td>
                      </tr>
                        <tr>        
                          <td class="capitalize"><span class="typcn typcn-pen"></span> Catatan Frontliner</td>
                          <td class="pl-2 pr-2">:</td>
                          <td>
                                <div class="sm-form tips"
                                  style="width:100%;"
                                >
                                  <textarea type="text" id="catatan_kasir" 
                                  style="width:100%;"
                                  name="catatan_kasir" rows="2" placeholder="catatan_frontliner..." class=" form-control md-textarea" 
                                  v-model="vdata.catatan_admin" ></textarea>
                                  <span class="tipstextL">Kolum untuk inputan catatan Frontliner</span>
                                </div>
                          </td>
                      </tr>
                      <tr>
                        <td class="capitalize">Tujuan</td>
                        <td class="px-2">:</td>
                        <td>
                          <select class='form-control' v-model="vdata.proses" >
                              <option value='1' selected="selected">Registered > Frontliner</option>
                              <option value='2' v-if="pilih=='adminapotek'" >Konsul > Dokter</option>  
                              <option value='3' v-if="pilih=='dokter' || pilih=='adminapotek' || pilih=='facial'" >Treatment > Nurse</option>
                              <option value='3A' v-if="pilih=='dokter' || pilih=='adminapotek' || pilih=='nurse'" >Treatment > Facial</option>
                              <option value='4' v-if="pilih=='nurse' || pilih=='adminapotek'" >Treatment Dokter  > Dokter</option>
                              <option value='5' v-if="pilih=='nurse'" >Buatkan Resep  > Dokter</option>
                              <option value='6' v-if="pilih=='dokter' || pilih=='nurse' || pilih=='facial'" >Menyiapkan Obat > Apoteker</option>
                            </select>
                        </td>
                      </tr>
                  </table>
                </div>
                  <img src="@/static/hospital.png" class="img-fluid float-left " style="height:40px;width:40px;" alt="">
                 <div class="text-right">
                  <button type="submit" v-if="transaksibutton" class="btn btn-sm btn-dark  text-sm" ><span class="typcn typcn-business-card"></span> Tambah Ticket Transaksi Baru +</button>
              </div>
        </form>
      </div>
    </div>
    <div class="===========================----=MODAL TRANSAKSI BARU END=----============================="></div>
    <div class="===========================----=MODAL BAYAR=----============================="></div>
    <div v-if="modalbayar" class="row" style="position:fixed;top:60px;right:10px;z-index:1000;width:100vw;">
        <div class="col-sm-9" @click="modalbayar=false" style="height:100vh;"></div>
        <div class="col-sm-3">
            <div class="bg-white rounded-lg p-3 shadow animate__animated animate__bounceInDown">
                <div class="sm-form ">
                    <label for="nota">Nota</label>
                    <input type="text" disabled id="nota" name="nota" class="form-control form-control-sm" placeholder="nota" v-model="vdata.nota" >
                </div>
                <div class="sm-form ">
                    <label for="uang">Uang : </label>
                    <input type="number" id="uang" name="uang" class="form-control form-control-sm" placeholder="uang" v-model="uang" >
                </div>
                <div class="sm-form">
                <textarea type="text" id="keterangan" name="keterangan" rows="2" placeholder="keterangan..." class="form-control md-textarea" v-model="vdata.keterangan"></textarea>
                </div>
                <hr>
                <p class="">total Rp. {{total}}</p>
                <p>Uang : {{uang}}</p>
                <p v-show="uang-total">Kembalian : {{uang-total}}</p>
                <hr>
                <button type="button" id="btnbayar2" @click="bayar" class="btn btn-sm btn-dark  "><span class="typcn typcn-export"></span> Bayar</button>
            </div>
        </div>
    </div>
      <div class="===========================----=NAVIGATION=----============================="></div>
      <div class="row">
        <div class="col-12 " :class="proses=='7' && pilih=='adminapotek'?'order-2':'order-1'">

          <div class="ml-3 sm:ml-0">
            <br>
            <span class="font-semibold text-lg p-3 mt-5 italic shadow rounded-lg capitalize" ><span class="typcn typcn-th-list"></span> Menu {{pilih}}</span>
              <div v-if="$store.state.users.level=='SuperAdmin'" class="row bg-white shadow rounded-t-lg pt-2 mt-5 scrollbarnya items" style="overflow: auto; white-space: nowrap;">
                    <div class="col-2" @click="pilih='adminapotek'">
                      <button type="button"  class="btn btn-sm   btn-block" :class="`${pilih=='adminapotek'?'btn-dark':'btn-outline-dark'}`" style="width:100%;">
                        Frontliner 
                      </button>
                    </div>
                    <div class="col-2" @click="pilih='apoteker'">
                      <button type="button" class="btn btn-sm   btn-block" :class="`${pilih=='apoteker'?'btn-dark':'btn-outline-dark'}`" style="width:100%;">
                        Apoteker
                      </button>
                    </div>
                    <div class="col-2" @click="pilih='dokter'">
                      <button type="button" class="btn btn-sm   btn-block" :class="`${pilih=='dokter'?'btn-dark':'btn-outline-dark'}`" style="width:100%;">
                        Dokter
                      </button>
                    </div>
                    <div class="col-2" @click="pilih='nurse'">
                      <button type="button" class="btn btn-sm   btn-block" :class="`${pilih=='nurse'?'btn-dark':'btn-outline-dark'}`" style="width:100%;">
                        Nurse
                      </button>
                    </div>
                    <div class="col-2" @click="pilih='facial'">
                      <button type="button" class="btn btn-sm   btn-block" :class="`${pilih=='facial'?'btn-dark':'btn-outline-dark'}`" style="width:100%;">
                        Facial Therapist
                      </button>
                    </div>
              </div>
              <div class="row bg-white shadow rounded-b-lg p-4 scrollbarnya " style="min-height:60vh;">
                <div class="col-sm-8">
                <div class="===========================----=LIST TICKET=----============================="></div>
                    <div class="row justify-content-center" v-if="ticket">
                    <div class="===========================----=FORM UPDATE TRANSAKSI NOTA=----============================="></div>
                      <div class="col-md-12">
                        <div class="row">
                          
                          <div class="col-6"  >
                            <div class="shadow rounded-lg p-3 text-sm">
                                <div v-if="pilih=='adminapotek'">
                                  <p class="capitalize">Catatan Frontliner</p>
                                  <div class="sm-form" >
                                    <textarea type="text" id="catatan_admin" name="catatan_admin" rows="4"  class="form-control md-textarea" v-model="vdata.catatan_admin"
                                    ></textarea>
                                  </div>
                                </div>
                                <div v-if="pilih=='dokter'">
                                  <p class="capitalize">Catatan Dokter</p>
                                  <div class="sm-form" >
                                    <textarea type="text" id="catatan_dokter" name="catatan_dokter" rows="4"  class="form-control md-textarea" v-model="vdata.catatan_dokter"
                                    ></textarea>
                                  </div>
                                </div>
                                <div v-if="pilih=='apoteker'">
                                  <p class="capitalize">Catatan Apoteker</p>
                                    <div class="sm-form" >
                                      <textarea type="text" id="catatan_apoteker" name="catatan_apoteker" rows="4"  class="form-control md-textarea" v-model="vdata.catatan_apoteker"
                                      ></textarea>
                                    </div>
                                </div>
                                <div v-if="pilih=='nurse'">
                                  <p class="capitalize">Catatan Nurse</p>
                                  <div class="sm-form" >
                                    <textarea type="text" id="catatan_nurse" name="catatan_nurse" rows="4"  class="form-control md-textarea" v-model="vdata.catatan_nurse"
                                    ></textarea>
                                  </div>
                                </div>
                                 <div v-if="pilih=='facial'">
                                  <p class="capitalize">Catatan Facial Therapist</p>
                                  <div class="sm-form" >
                                    <textarea type="text" id="catatan_facial" name="catatan_facial" rows="4"  class="form-control md-textarea" v-model="vdata.catatan_facial"
                                    ></textarea>
                                  </div>
                                </div>
                          </div>
                          </div>
                          <!-- Jika hari yang sama maka bisa edit, jika beda maka hilangkan fungsi push -->
                          <div class="col-6" v-if="vdata.nota.split('KA')[1].split('-')[0]==today">
                            <!-- v-if="ticket.proses!='8'" -->
                          <div class="shadow rounded-lg p-3" >
                            <p class="font-semibold">
                            Ticket/Nota : <span class="typcn typcn-business-card"></span> {{vdata.nota}}
                            </p>
                            <hr class="my-2">
                            <p class="font-semibold">Lanjutkan ticket berikut ke <span class="typcn typcn-arrow-right"></span> </p>
                            <select class='form-control' v-model="proses" @change="pilihButton=true">
                              <option value='1' v-if="pilih=='adminapotek' || pilih=='dokter' || pilih=='nurse' || pilih=='facial'">Registered > Frontliner</option>
                              <option value='2' v-if="pilih=='adminapotek'" >Konsul > Dokter</option>  
                              <option value='3' v-if="pilih=='dokter' || pilih=='adminapotek' || pilih=='facial'" >Treatment > Nurse</option>
                              <option value='3A' v-if="pilih=='dokter' || pilih=='adminapotek' || pilih=='nurse'" >Treatment > Facial Therapist</option>
                              <option value='4' v-if="pilih=='nurse' || pilih=='adminapotek'" >Treatment Dokter  > Dokter</option>
                              <option value='5' v-if="pilih=='nurse'" >Buatkan Resep  > Dokter</option>
                              <option value='6' v-if="pilih=='dokter' || pilih=='nurse' || pilih=='facial'" >Menyiapkan Obat > Apoteker</option>
                              <option value='7' v-if="pilih=='adminapotek' || pilih=='dokter' || pilih=='nurse' || pilih=='apoteker' || pilih=='facial'" >Bayar  > Frontliner</option>
                            </select>
                            <div class="p-3 mb-3">
                              <button type="button" v-if="pilihButton" @click="teruskanTransaksi" class="btn btn-sm btn-success float-right"><span class="typcn typcn-arrow-right"></span> Teruskan</button>
                            </div>  
                          </div>
                          </div>
                        </div>
                        <div class="shadow rounded-lg p-3 text-sm mt-2">
                            <div class="table-responsive">
                              <table class="table table-sm table-striped table-bordered text-lg">
                                <tr v-if="vdata.catatan_admin">        
                                    <td class="capitalize font-semibold"><span class="typcn typcn-document-text"></span>  Catatan Frontliner</td>
                                    <td class="pl-2 pr-2">:</td>
                                    <td>
                                        <div  style="white-space: pre-line;">{{vdata.catatan_admin}}</div>
                                    </td>
                                </tr>
                                <tr v-if="vdata.catatan_dokter">        
                                    <td class="capitalize font-semibold"><span class="typcn typcn-document-text"></span>  Catatan Dokter</td>
                                    <td class="pl-2 pr-2">:</td>
                                    <td>
                                        <div style="white-space: pre-line;">{{vdata.catatan_dokter}}</div>
                                    </td>
                                </tr>
                                <tr v-if="vdata.catatan_apoteker">        
                                    <td class="capitalize font-semibold"><span class="typcn typcn-document-text"></span>  Catatan Apoteker</td>
                                    <td class="pl-2 pr-2">:</td>
                                    <td>
                                        <div  style="white-space: pre-line;">{{vdata.catatan_apoteker}}</div>
                                    </td>
                                </tr>
                                <tr v-if="vdata.catatan_nurse">        
                                    <td class="capitalize font-semibold"><span class="typcn typcn-document-text"></span>  Catatan Nurse</td>
                                    <td class="pl-2 pr-2">:</td>
                                    <td>
                                        <div style="white-space: pre-line;">{{vdata.catatan_nurse}}</div>
                                    </td>
                                </tr>
                                <tr v-if="vdata.catatan_facial">        
                                    <td class="capitalize font-semibold"><span class="typcn typcn-document-text"></span>  Catatan Facial Therapist</td>
                                    <td class="pl-2 pr-2">:</td>
                                    <td>
                                        <div style="white-space: pre-line;">{{vdata.catatan_facial}}</div>
                                    </td>
                                </tr>
                              </table>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div style="height:50vh;" class="d-flex justify-content-center align-items-center ">
                          <div style="width:100%">
                            <div class="text-center font-semibold text-lg italic">
                            <span class="typcn typcn-business-card"></span> Ticket Flow Aplikasi Apotek
                            <br>
                            <br>
                            <button type="button"  class="btn btn-sm btn-style8  " v-if="pilih=='adminapotek'" @click="aktifModalTransaksi">Tambah Transaksi Baru + </button>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="col-sm-4" v-if="!prosesTransaksi">
                    <button type="button" class="btn btn-sm  ml-1" :class="ticketType=='latest'?'btn-dark':'btn-dark-outline shadow'" @click="ubahTicket('latest')"> <span class="typcn typcn-business-card"></span> 
                    Latest</button>
                    <button type="button" class="btn btn-sm  ml-1" :class="ticketType=='latest'?'btn-dark-outline shadow':'btn-dark'" @click="ubahTicket('history')"><span class="typcn typcn-business-card"></span> 
                    History</button>
                    <button type="button" class="btn btn-sm btn-style10 ml-1 text-xs " @click="$router.push('/admin/transaksi/apotek/ticketflow')"><span class="typcn typcn-business-card"></span> 
                    Ticket Flow <span class="typcn typcn-arrow-right"></span> </button>
                    <div class="sm-form ">
                        <input type="date" id="search" name="search" class="form-control p-1 rounded-lg shadow" placeholder="search" v-model="pencarianHistory" >
                        <input type="text" id="search2" name="search2" class="form-control p-1 rounded-lg shadow" placeholder="   search" v-model="pencarianPelanggan" >
                    </div>
                      <button type="button" class="btn btn-sm btn-dark  float-right text-xs mt-2"><span class="typcn typcn-arrow-loop" @click="refreshBack"></span></button>
                      <hr class="my-5">
                    <div class="" v-for="(item, index) in tdTransaksi" :key="index+'nota'" >
                      <div v-if="tdTransaksi.length>0">
                        <div class="===========================----=LIST TRANSAKSI APOTEK=----============================="></div>
                          
                        <div v-if="ticketType=='latest'">
                          <div v-if="item.proses!='8'">
                            <p class="font-semibold" v-if="index=='0'"><span class="typcn typcn-business-card"></span> List Ticket </p>
                              <div v-if="(pilih=='adminapotek' && item.proses=='1')||(pilih=='adminapotek' && item.proses=='7') ?true:(pilih=='dokter' && item.proses=='2')||(pilih=='dokter' && item.proses=='4')||(pilih=='dokter' && item.proses=='5')?true:(pilih=='nurse' && item.proses=='3')?true:(pilih=='facial' && item.proses=='3A')?true:(pilih=='apoteker' && item.proses=='6') "
                              @click="getTicket(item)" class="shadow hover:text-red-400 hover:shadow-lg anim cursor-pointer rounded-lg p-3 text-xs mb-2" >
                                <p class="float-right  font-semibold italic text-gray-500">{{format2(item.tanggal)}}</p>
                                <p class="font-semibold">{{item.nota}}  
                                </p>
                                <hr class="mt-1 mb-1">
                                <div class="float-right">
                                  <button type="button" 
                                  v-if="item.proses=='1' || pilih=='dokter'"
                                  @click="hapusTransaksi(item)" class="btn btn-sm btn-danger text-xs ">X</button>
                                </div>
                                <table class="">
                                  <tr>
                                    <td class="font-semibold">Pasien</td>
                                    <td class="pl-2 pr-2">:</td>
                                    <td class="font-semibold">{{item.pelanggan}}</td>
                                  </tr>
                                    <tr>
                                    <td class="font-semibold">Keterangan</td>
                                    <td class="pl-2 pr-2">:</td>
                                    <td class="font-semibold">{{item.catatan_admin}}</td>
                                  </tr>
                                </table>
                                <button type="button" class="btn btn-sm ml-3 float-right"
                                :class="`${item.proses=='1'?'btn-dark':item.proses=='2'||item.proses=='3'||item.proses=='4'?'btn-warning':item.proses=='5'?'btn-primary':item.proses=='6'?'btn-danger':item.proses=='7'?'btn-success':'btn-style10'}`">
                                <span class="typcn typcn-media-record"></span> {{item.proses=='1'?'Registered':item.proses=='2'?'Konsul':item.proses=='3'?'Treatment':item.proses=='4'?'Treatment Dokter':item.proses=='5'?'Buat Resep':item.proses=='6'?'Menyiapkan Obat':item.proses=='7'?'Bayar':'Selesai'}}
                                </button>
                              </div>
                          </div>
                        </div>
                        <div v-else>
                          <div  v-if="item.proses=='8'">
                            <p class="font-semibold" v-if="index=='0'"><span class="typcn typcn-business-card"></span> History Ticket</p>
                            
                              <div 
                              class="shadow hover:text-red-400 hover:shadow-lg anim cursor-pointer rounded-lg p-3 text-xs mb-2" >
                                <p class="float-right  font-semibold italic text-gray-500"> {{format2(item.tanggal)}}</p>
                                <div v-if="!item.tanggal">
                                  <div class="sm-form ">
                                      <input type="datetime-local" id="tanggal" name="tanggal" class="form-control p-2 rounded-lg shadow" placeholder="tanggal" v-model="updates['tanggal']" >
                                  </div>
                                  <button type="button" class="btn btn-sm btn-success  " @click="updateTanggal(item)"><span class="typcn typcn-edit"></span></button>

                                </div>
                                <div @click="getTicket(item)">

                                <p class="font-semibold">{{item.nota}}  
                                </p>
                                <hr class="mt-1 mb-1">
                                <div class="float-right">
                                  <button type="button" 
                                  v-if="$store.state.users.level=='SuperAdmin'"
                                  @click="hapusTransaksi(item)" class="btn btn-sm btn-danger text-xs ">X</button>
                                </div>
                                <table class="">
                                  <tr>
                                    <td class="font-semibold">Pasien</td>
                                    <td class="pl-2 pr-2">:</td>
                                    <td class="font-semibold">{{item.pelanggan}}</td>
                                  </tr>
                                    <tr>
                                    <td class="font-semibold">Keterangan</td>
                                    <td class="pl-2 pr-2">:</td>
                                    <td class="font-semibold">{{item.catatan_admin}}</td>
                                  </tr>
                                </table>
                                <button type="button" class="btn btn-sm ml-3 float-right"
                                :class="`${item.proses=='1'?'btn-dark':item.proses=='2'||item.proses=='3'||item.proses=='4'?'btn-warning':item.proses=='5'?'btn-primary':item.proses=='6'?'btn-danger':item.proses=='7'?'btn-success':'btn-style10'}`">
                                <span class="typcn typcn-media-record"></span> {{item.proses=='1'?'Registered':item.proses=='2'?'Konsul':item.proses=='3'?'Treatment':item.proses=='4'?'Treatment Dokter':item.proses=='5'?'Buat Resep':item.proses=='6'?'Menyiapkan Obat':item.proses=='7'?'Bayar':'Selesai'}}
                                </button>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="===========================----=LIST TRANSAKSI APOTEK END=----============================="></div>
                      </div>
                      <div v-else>
                        <div style="height:100px;" class="d-flex justify-content-center align-items-center shadow-lg rounded-lg">
                            <div style="width:100%">
                              <p class="text-center">
                                Ticket Kosong !
                              </p>
                            </div>
                        </div>
                      </div>
                    </div>
                  
                </div>
                <div class="col-sm-4" v-else>
                  <div class="text-right  p-2 ">
                    <button type="button" @click="refreshBack" class="btn btn-sm btn-dark  "><span class="typcn typcn-arrow-loop"></span></button>
                  </div>
                  <div class="p-2 shadow rounded" v-if="pelangganya.length>0">
                    <p class="text-lg font-semibold">Personal Info</p>
                    <hr class="my-2">
                    <p>ID : {{pelangganya[0].id}}</p>
                    <p>Nama : {{pelangganya[0].nama}}</p>
                    <p>Jenis Kelamin : {{pelangganya[0].jk}}</p>
                    <p>No HP : {{pelangganya[0].nohp}}</p>
                    <p>Alamat : {{pelangganya[0].alamat}}</p>
                    <hr class="my-2">
                    <div>
                      <button  @click="$router.push(`/admin/pelanggan/review?pelanggan=${vdata.id_pelanggan}&nota=${vdata.nota}&level=${$store.state.users.level}`)" type="button" class="btn btn-sm btn-dark  btn-block">Review Pasien</button>
                      <hr v-if="['dokter','SuperAdmin','nurse','apoteker','facial'].includes($store.state.users.level)" class="my-2">
                      <button v-if="['dokter','SuperAdmin','nurse','apoteker','facial'].includes($store.state.users.level)" @click="$router.push(`/admin/pelanggan/listhistory?pelanggan=${vdata.id_pelanggan}`)" type="button" class="btn btn-sm btn-dark  btn-block">History Review Pasien</button>
                    </div>
                  </div>
                  <div v-else>
                      <v-select :options="pelanggans" label="item_data" v-model="temp.id_pelanggan" :reduce="e => e.id"></v-select>
                      <button type="button" @click="changePelanggan" class="btn btn-sm btn-dark  my-2">Ganti Pasien</button>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="col-12 "  :class="proses=='7' && pilih=='adminapotek'?'order-1':'order-2'">
            <div class="===========================----=INPUTAN BARANG=----============================="></div>
            <div class="mt-2 shadow p-3 rounded-lg" >
              <p class="font-semibold text-sm">List item</p>
              <hr class="mt-1 mb-3">
              <div class="===========================----=FORM TRANSAKSI KERANJANG=----============================="></div>
              <form  @submit.prevent="submit" >
                <div class="row justify-content-center">
                  <div class=" col-sm-5 3" v-if="pilih=='nurse' || pilih=='facial' || pilih=='dokter' || pilih=='adminapotek' && !modaltransaksi && this.vdata.id_pelanggan" style="font-size:12px;">
                    <div class="shadow p-3 rounded-lg bg-white">
                    <div class="sm-form">
                      <input
                        type="text"
                        id="id"
                        name="id"
                        class="form-control form-control-sm"
                        placeholder="id"
                        v-model="vdata.id"
                        disabled
                      />
                    </div>
                    <table class="table table-sm table-responsive">
                    <tr>        
                      <td class="capitalize">(F1) barcode</td>
                      <td class="pl-2 pr-2">:</td>
                      <td colspan="4">
                        <select class='form-control' v-model="produkFilter">
                          <option value="8">Obat</option>
                          <option value="10" v-if="pilih!='adminapotek'">Jasa / Treatment</option>
                        </select>
                          <v-select id="f1" :options="tdProduk" label="item_data" v-model="vdata.id_produk" @input="cekstok();hitung_total()" :reduce="e => e.id"></v-select>
                      </td>
                  </tr>
                    <tr>        
                      <td class="capitalize">Jumlah</td>
                      <td class="pl-2 pr-2">:</td>
                      <td colspan="4">
                          <div class="sm-form ">
                              <input required type="number" @change="hitung_total" :disabled='stok==0' min="1" :max="stok" id="jumlah" name="jumlah" class="form-control form-control-sm" placeholder="jumlah" v-model="vdata.jumlah" >
                          </div>
                          <div class="float-right">
                              <button type="button" class="btn btn-sm btn-dark  " id="tambah" @click="kurang">(F2) -</button>
                              <button type="button" class="btn btn-sm btn-dark  " id="kurang" @click="tambah">(F3) +</button>
                          </div>
                          <p class="text-xs text-red-300">Stok sisa : {{stok}}</p>
                      </td>
                  </tr>
                    <tr>        
                      <td class="capitalize">harga</td>
                      <td class="pl-2 pr-2">:</td>
                      <td>
                          <div class="sm-form "  v-if="$store.state.users">
                              <input type="number" :disabled="$store.state.users.level!='SuperAdmin'" @change="hitung_total" id="harga" name="harga" class="form-control form-control-sm" placeholder="harga" v-model="vdata.harga" >
                          </div>
                      </td>
                      <td v-if="['dokter','SuperAdmin'].includes($store.state.users.level)" class="capitalize">diskon (%)</td>
                      <td v-if="['dokter','SuperAdmin'].includes($store.state.users.level)" class="pl-2 pr-2">:</td>
                      <td v-if="['dokter','SuperAdmin'].includes($store.state.users.level)">
                          <div class="sm-form " v-if="$store.state.users">
                              <input type="number"  @change="hitung_total" id="diskon" name="diskon" class="form-control form-control-sm" placeholder="diskon" v-model="vdata.diskon" >
                          </div>
                      </td>
                  </tr>
                    <tr>        
                      <td class="capitalize">total</td>
                      <td class="pl-2 pr-2">:</td>
                      <td class="font-bold text-lg" >
                          Rp. {{vdata.total}}
                      </td>
                        <td class="capitalize">Satuan</td>
                      <td class="pl-2 pr-2">:</td>
                      <td class="text-xs" >
                          <div v-if="$store.state.users">
                            <v-select :options="satuans" :disabled="$store.state.users.level!='SuperAdmin'" label="satuan" v-model="vdata.satuan" @input="hitung_total()" :reduce="e => e.id"></v-select>
                          </div>
                      </td>
                  </tr>
                    <tr v-if="vdata.id_pelanggan">        
                      <td class="capitalize">Membership</td>
                      <td class="pl-2 pr-2">:</td>
                      <td>
                        <span class="font-bold">
                          {{membership==0?'Bukan Membership':'Membership'}}
                        </span>
                        <span class="font-bold" v-if="membership==1">
                          <button type="button" class="btn btn-sm btn-primary  " @click="tambahDiskon">
                            {{diskon_membership}} %
                          </button>
                        </span>
                      </td>
                      <td class="capitalize">Total Beli Pasien</td>
                      <td class="pl-2 pr-2">:</td>
                      <td>
                        <span class="font-bold">
                          Rp. {{total_beli_pelanggan}}
                        </span>
                      </td>
                  </tr>
                  </table>
                    <div class="mt-3 p-3" v-if="ticket">
                      <button
                         v-if="ticket.proses!='8'"
                        type="submit"
                        class="btn btn-sm"
                        id='btnsubmit'
                        :class="
                          btn == 'tambah'
                            ? 'btn-primary'
                            : btn == 'update'
                            ? 'btn-success'
                            : 'btn-danger'
                        "
                      >
                        <span class="typcn typcn-info"></span> (F5) Proses {{ btn }}
                      </button>
                      <div class="float-right" v-if="ticket.proses!='8'">
                        <button
                          type="button"
                          v-show="btn!='tambah'"
                          @click="gantiBtn"
                          class="btn btn-sm btn-dark"
                        >
                          <span class="typcn typcn-edit"></span> Tambah
                        </button>
                        <button
                          type="button"
                          @click="btn = 'delete'"
                          v-show="btn!='tambah'"
                          class="ml-3 btn btn-sm btn-danger"
                        >
                          <span class="typcn typcn-delete"></span> Delete
                        </button>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class=" col-sm-7 p-2" style="font-size:12px;">
                    <div class="shadow bg-white rounded-lg p-2" v-if="datanya.length>0">
                      <div  class="">
                        <div v-if="pilih=='adminapotek'||pilih=='Admin'||pilih=='SuperAdmin'">
                          <button  type="button" id="btnbayar" @click="modalbayar=true" class="btn btn-sm btn-style9   waves-effect float-right text-xs"><span class="typcn typcn-export"></span> Bayar</button>
                          <!-- <button  type="button" @click="deleteAll" class="btn btn-sm btn-dark  "><span class="typcn typcn-eject"></span> Delete All</button> -->
                        </div>
                      <br>
                      <br>
                      <p class="font-bold">Nota Transaksi : {{vdata.nota}}</p>
                      <p class="font-bold" v-if="ticket.uang">Uang Pasien : Rp. {{ticket.uang}}</p>
                          <Btables :tables="datanya"  :cari="['']" :hide="['id_produk','kategori','satuan','stok','keluar','id_user','tanggal_w','id_pelanggan']" @selected="ambil" ref="btable" />
                      </div>
                    </div>
                  </div>
                
                </div>
              </form>
          </div>
        </div>
      </div>
    <div style="height:200px;"></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/_autophp.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
let sdb2 = new autophp();
import firebase from 'firebase'
import uniqid from 'uniqid'
let rdb=firebase.database()
import _ from 'lodash'
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      notif:false,
      transaksibutton:true,
      pilihButton:false,
      pilih:'adminapotek',
      listPilih:[
        'adminapotek',
        'dokter',
        'apoteker',
        'nurse',
        'facial',
      ],
      ticketType:'latest',
      today:'',
      nota:null,
      selected: false,
      modalbayar:false,
      modaltransaksi:false,
      prosesTransaksi:false,
      proses:'',
      pilihanProses:'',
      pencarianHistory:'',
      pencarianPelanggan:'',
      vdata: {
          jumlah:0,
          catatan_admin:''
      },
      temp:{},
      produks:[],
      transaksiNota:[],
      pelanggans:[],
      ticket:null,
      btn: "tambah",
      stok:0,
      datanya:[],
      total:0,
      uang:'',
      diskons:false,
      skip_ambil_diskon:false,
      satuans:[],
      total_beli_pelanggan:0,
      membership:0,
      diskon_membership:0,
      produkFilter:8,
      updates:{},
    };
  },
  watch:{
    pilih(){
      this.getProduknya()
    },
    pencarianHistory(){
      this.getData()
    }
  },
  computed:{
    tdProduk(){
      let that=this;
      let data =this.produks;
      data=data.filter(e=>{
        if(e.kategori==that.produkFilter){
          return e
        }
      })
      return data;
    },
    tdTransaksi(){
      let that=this;
      let data =this.transaksiNota;
      // console.log('tdtransaksi',data)
      data=data.filter(e=>{
        let pelanggan = e.pelanggan??'--------';
        if(e.tanggal.indexOf(that.pencarianHistory)!=-1 && (pelanggan.toLowerCase().indexOf(that.pencarianPelanggan.toLowerCase())!=-1 || e.nota.toLowerCase().indexOf(that.pencarianPelanggan.toLowerCase())!=-1)){
          return e;
        }
      })
      return data;
    },
    pelangganya(){
      let that=this;
      let data = this.pelanggans;
      if(this.vdata.nota){
        data=data.filter(e=>e.id==this.vdata.id_pelanggan)
      }
      return data;
    }
  },
  methods: {
      async changePelanggan(){
        // console.log(this.temp)
        // console.log(this.vdata)
        await sdb.collection("test2").doc().select(`update app_kasir_transaksi_nota set id_pelanggan='${this.temp.id_pelanggan}' where nota='${this.vdata.nota}'`)
        sdb.alert('Berhasil Update Data Pasien','bg-green-400');
        this.refreshBack();
      },
      refreshBack(){
        window.location.reload();
        //   this.$router.push('/admin')
        // setTimeout(() => {
        //   this.$router.push('/admin/transaksi/apotek')
        // }, 500);
      },
      updateTanggal(data){
        // console.log(`update app_kasir_transaksi_nota set tanggal="${this.updates.tanggal}" where id='${data.id}'`)
        sdb.collection("app_kasir_transaksi_nota",false).doc().select(`update app_kasir_transaksi_nota set tanggal="${this.updates.tanggal}" where id='${data.id}'`).then(res => {
            // console.log(res);
          sdb.alert('berhasil update','bg-green-400');
          window.location.reload();
        });
        
      },
      ubahTicket(item){
        this.ticketType=item;
        this.$forceUpdate();
      },
      getTicket(item){
        // console.log('item',item)
        this.ticket=item;
        this.uang=parseInt(item.uang)
        this.vdata.nota=item.nota;
        this.proses=item.proses
        this.pilihanProses=item.proses
        this.prosesTransaksi=true;
        this.vdata.catatan_admin=item.catatan_admin;
        this.vdata.catatan_dokter=item.catatan_dokter;
        this.vdata.catatan_apoteker=item.catatan_apoteker;
        this.vdata.catatan_nurse=item.catatan_nurse;
        this.vdata.catatan_facial=item.catatan_facial;
        this.vdata.id_pelanggan=item.id_pelanggan
        this.cekmembership();
        this.getDatanya();
      },
      aktifModalTransaksi(){
        this.vdata={};
        this.getNota()
        this.getTanggal();
        this.modaltransaksi=!this.modaltransaksi
      },  
      offModalTransaksi(){
        this.modaltransaksi=!this.modaltransaksi
        // console.log(this.vdata)
        this.$forceUpdate();
      },
      async tambahTransaksiBaru(){
        let that=this;
        this.transaksibutton=false;
        this.$forceUpdate();
       
          if(this.vdata.id_pelanggan && this.vdata.catatan_admin){
            let data = this.datanya;
              data=data.map(e=>{
                  return e.id
              })
              let vdata={
                    total:0,
                    uang:0,
                    id_user:this.$store.state.users.id,
                    id_pelanggan:this.vdata.id_pelanggan,
                    tanggal_w:this.format(new Date(this.vdata.tanggal)),
                    cabang:this.$store.state.data.toko.cabang,
                    catatan_admin:this.vdata.catatan_admin,
                    nota:this.vdata.nota,
                    tanggal:this.vdata.tanggal,
                    proses:that.vdata.proses, // 1=kasir ,2=dokter, 3=apoteker, 4=nurse , 3A=facial
                    status:'1',
                    list_id:data.length>0?data.toString():'',
                    transaksi:'apotek',
                    id_admin:this.$store.state.users.id
                }
              try{
                let res = await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id='${this.vdata.id_pelanggan}'`)
let txt=`
${that.vdata.nota}
${that.pilih.toUpperCase()} 
----------------------------
Pasien Baru Registered oleh Frontliner
Atas Nama : ${res[0].nama}
Catatan : ${this.vdata.catatan_admin}
`;
                that.sendNotif(txt,'Dokter');
                that.sendNotif(txt,'Nurse');
                that.sendNotif(txt,'Facial');
                that.sendNotif(txt,'Apoteker');
                that.sendNotif(txt,'Admin');
                // TAMBAH HISTORY PELANGGAN
                await sdb.collection('app_kasir_pelanggan_history').doc().set({
                    pelanggan:that.vdata.id_pelanggan,
                    tanggal:new Date(),
                    create_by:that.$store.state.users.username,
                    id_transaksi_nota:that.vdata.nota
                })
                // axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-572742884", text:txt }).then(res=>{ })
                await sdb.collection('app_kasir_transaksi_nota').doc().set(vdata)
                setTimeout(() => {
                  this.transaksibutton=true;
                  this.$forceUpdate();
                }, 1000);
                sdb.alert('Transaksi Berhasil ditambah!','bg-green-400');
                that.modaltransaksi=false
                that.getData();
                that.getNota();
                that.$router.push('/admin')
              }
              catch(e){
                sdb.alert('Ada fungsi error!');
                console.log('error',e)
              }
              
          }else{
            sdb.alert('Tambahkan Pasien dan catatan terlebih dahulu!')
             setTimeout(() => {
                  this.transaksibutton=true;
                  this.$forceUpdate();
                }, 1000);
          }
          
      },
      async hapusTransaksi(item){
        // console.log('item',item)
        if(await sdb.confirm('Apakah yakin ingin menghapus data transaksi? ')){
            sdb.collection("app_kasir_transaksi_nota",true).doc(item.id).delete().then(async (res) => {
                sdb.collection("app_kasir_transaksi_nota",false).doc().select(`delete from app_kasir_transaksi_nota where nota='${item.nota}'`).then(res2 => {})
                this.getData();
                sdb.alert('Berhasil Delete data transaski!')
            }).catch(err=>{
              sdb.alert('Error menghapus data transaksi!')
            });
          }
      },
      async teruskanTransaksi(){
        if(this.pilihanProses=='2'||this.pilihanProses=='3'||this.pilihanProses=='3A'||this.pilihanProses=='4'||this.pilihanProses=='5'){
          sdb.alert('Tambahkan item/Jasa di transaksi,<br>  apabila ada item/jasa berbayar yang dilakukan!','bg-orange-400')
          if(this.datanya.length==0){
            alert('Data Transaksi Tidak ada!');
            return;
          }
        }
        setTimeout(async () => {
          let that=this;
          if(this.proses.length>0){
            // if(this.proses=='7' && this.datanya.length==0){
            //     alert('Data Transaksi tidak ada!');
            //     return;
            // }
            if(confirm('Apakah yakin melanjutkan proses ? ')){
              let data={
                id:this.ticket.id,
                proses:this.proses,
                catatan_admin:this.vdata.catatan_admin,
                catatan_dokter:this.vdata.catatan_dokter,
                catatan_nurse:this.vdata.catatan_nurse,
                catatan_apoteker:this.vdata.catatan_apoteker,
                catatan_facial:this.vdata.catatan_facial,
              }
              // console.log(this.vdata)
              if(that.pilih=='dokter'){
                data.id_dokter=that.$store.state.users.id;
              }else if(that.pilih=='nurse'){
                data.id_nurse=that.$store.state.users.id;
              }else if(that.pilih=='apoteker'){
                data.id_apoteker=that.$store.state.users.id;
              }else if(that.pilih=='facial'){
                data.id_facial=that.$store.state.users.id;
              }
              that.prosesTransaksi=false;
              let res2 = await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id='${this.vdata.id_pelanggan}'`)
              let teruskan = await sdb.collection('app_kasir_transaksi_nota').doc().set(data)
              console.log('teruskan',teruskan)
              // that.getData();
              rdb.ref('/ticket/admin').set({uniqid:uniqid()})
              this.ticket=null;
              var proses='';
              if(that.proses=='1'){
                proses=proses+`Pasien Baru Registered oleh Frontliner`;
              }else if(that.proses=='2'){
                proses=proses+`Permintaan Konsultasi ke Dokter`;
              }else if(that.proses=='3'){
                proses=proses+`Permintaan Treatment ke Nurse`;
              }else if(that.proses=='3A'){
                proses=proses+`Permintaan Treatment ke Facial Therapist`;
              }else if(that.proses=='4'){
                proses=proses+`Permintaan Konsultasi ke Dokter`;
              }else if(that.proses=='6'){
                proses=proses+`Permintaan Menyiapkan Obat ke Apoteker`;
              }else if(that.proses=='7'){
                proses=proses+`Permintaan Proses Pembayaran Ticket ke Frontliner`;
              }
let target=that.proses=='2'||that.proses=='4'?'Dokter':that.proses=='3'?'Nurse':that.proses=='3A'?'Facial':that.proses=='6'?'Apoteker':"Admin";                  
let txt=`
${that.vdata.nota}
${that.pilih.toUpperCase()} > ${target} 
----------------------------
${proses}
Atas Nama : ${res2[0].nama}
`;
// console.log(txt)
              that.sendNotif(txt,target);
              that.vdata={
                jumlah:0,
                  catatan_admin:''
              }
              sdb.alert('Transaksi Berhasil di teruskan!');
              that.$router.push('/')
              that.$forceUpdate();
            }
          }
        }, 800);
      },
      async sendNotif(txt,target){
        let that=this;
        if(this.notif){
            if(target=='Dokter'){ // GROUP DOKTER
            await axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-1001797459274", text:txt })
          }else if(target=='Nurse'){ // GROUP NURSE
            await axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-1001771828590", text:txt })
          }else if(target=='Facial'){ // GROUP FACIAL
            await axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-1001616856704", text:txt })
          }else if(target=='Apoteker'){ // GROUP APOTEKER
            await axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-1001633702854", text:txt })
          }else if(target=='Admin'){ // GROUP ADMIN
            await axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-1001692707812", text:txt })
          }
          that.$forceUpdate();
        }
      },
      gantiBtn(){
        // alert('test')
        this.btn = 'tambah';
        let nota=this.vdata.nota
        let proses=this.proses ;
        let id_pelanggan=this.vdata.id_pelanggan;
        let catatan_admin=this.vdata.catatan_admin;
        let catatan_dokter=this.vdata.catatan_dokter;
        let catatan_nurse=this.vdata.catatan_nurse;
        let catatan_apoteker=this.vdata.catatan_apoteker;
        let catatan_facial=this.vdata.catatan_facial;
        this.vdata = {
          nota:nota,
          jumlah:0,
          harga:0,
          id_pelanggan:id_pelanggan
        };
        this.vdata.catatan_admin=catatan_admin;
        this.vdata.catatan_dokter=catatan_dokter;
        this.vdata.catatan_nurse=catatan_nurse;
        this.vdata.catatan_facial=catatan_facial;
        this.vdata.catatan_apoteker=catatan_apoteker;
        this.skip_ambil_diskon=false;
        // this.getTanggal();
        // this.getNota();
      },
      async cekmembership(){
        let that=this;
        this.membership=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.vdata.id_pelanggan}`)
        this.membership=parseInt(this.membership[0].membership)
        let listmembership = await sdb.collection("app_kasir_pelanggan_membership",false).doc().select(`select * from app_kasir_pelanggan_membership`)
        let diskon
        this.total_beli_pelanggan=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`select * from app_kasir_transaksi_nota where id_pelanggan='${this.vdata.id_pelanggan}'`)
        this.total_beli_pelanggan=this.total_beli_pelanggan.reduce((e,n)=>{
          return e+(parseInt(n.total))
        },0)
        if(this.membership!=0){
          diskon=listmembership.filter(e=>{
            if(this.total_beli_pelanggan>parseInt(e.total_beli)){
              return e
            }
          })
          if(diskon.length>0){
            this.diskon_membership=parseInt(diskon[0].diskon)
          }
        }
        // console.log(this.vdata.id_pelanggan,this.total_beli_pelanggan)
        this.$forceUpdate();        
        
      },
      tambahDiskon(){
        this.vdata.diskon=parseInt(this.vdata.diskon)+parseInt(this.diskon_membership)
        this.hitung_total();
        this.$forceUpdate();
      },
      async tambahPointPelanggan(){
        if(parseInt(this.getJam())<16){ // jam pagi
          let data={}
          data.id = this.vdata.id_pelanggan
          let pelanggan=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.vdata.id_pelanggan}`)
          data.point=parseInt(pelanggan[0].point)+25
          // console.log('data pelanggan',data)
          sdb.collection('app_kasir_pelanggan').doc().set(data).then(res=>{
              // console.log(res)
          })
        }else if(parseInt(this.getJam())>16){ // jam sore/malam
           let data={}
          data.id = this.vdata.id_pelanggan
          let pelanggan=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.vdata.id_pelanggan}`)
          data.point=parseInt(pelanggan[0].point)+35
          // console.log('data pelanggan',data)
          sdb.collection('app_kasir_pelanggan').doc().set(data).then(res=>{
              // console.log(res)
          })
        }
    
      },
      tambah(){
        if(this.vdata.jumlah>parseInt(this.stok)-1){
          
          }else{
            this.vdata.jumlah=parseInt(this.vdata.jumlah)+1;
            this.hitung_total()
          }
      },
      kurang(){
        if(this.vdata.jumlah<1){

        }else{
          this.vdata.jumlah=parseInt(this.vdata.jumlah)-1;
          this.hitung_total()
        }
      },
      format(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD");
      },
      format2(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD HH:mm");
      },
      format3(tanggal) {
        return this.$datefns.format(new Date(tanggal),"DD-MM-YYYY");
      },
      formatNota(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYYMMDD");
      },
      formatNota2(tanggal){
        return this.$datefns.format(new Date(tanggal),"YYYYMMDD-mmss");
      },
      getJam(tanggal) {
        return this.$datefns.format(new Date(),"HH");
      },
      async getNota(){
          //Delete nota yang tidak ada di transaksi tapi ada di keranjang
          this.transaksibutton=false;
          this.$forceUpdate();
          await sdb.collection("app_kasir_keranjang").doc().select(`delete from app_kasir_keranjang where nota IN (SELECT nota FROM app_kasir_keranjang WHERE nota NOT IN (SELECT nota FROM app_kasir_transaksi_nota))`)
          let code='KA';//KA = kasir, ON=Online, OF=OFFLINE
          let random=Math.floor(Math.random() * (100 - 1 + 1)) + 1;
          this.vdata.nota=code+this.formatNota2(new Date())+'-'+this.$store.state.users.id+'-'+random;
          // console.log('nota',this.vdata.nota)
          this.transaksibutton=true;
          this.$forceUpdate();
      },
     async cekstok(item){
        // console.log(this.vdata)
          let stok=await sdb.collection("app_kasir_produk",false).doc().get("*",`where id='${this.vdata.id_produk}' order by id`).then(res => {
         if(!this.skip_ambil_diskon){
             this.vdata.diskon=res[0].diskon
         }
          this.vdata.harga=res[0].harga
          this.vdata.satuan=res[0].satuan
        return parseInt(res[0].stok)
      });
      let masuk = await sdb.collection("app_kasir_stok_masuk",false).doc().get("*",`where id_produk='${this.vdata.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      let keluar = await sdb.collection("app_kasir_stok_keluar",false).doc().get("*",`where id_produk='${this.vdata.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      
      // let transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota where tb2.id_produk='${this.vdata.id_produk}' order by tb1.id`).then(res => {
      //      return res.reduce((e,n)=>{
      //       return e+parseInt(n.jumlah)
      //       },0)
      // });
       let keranjang=await sdb.collection("app_kasir_keranjang",false).doc().select(`SELECT * FROM app_kasir_keranjang  tb1 where tb1.id_produk='${this.vdata.id_produk}' order by tb1.id`).then(res => {
           return res.reduce((e,n)=>{
            return e+parseInt(n.jumlah)
            },0)
      });
      stok=stok+(masuk-keluar-keranjang)
      this.stok=stok;
      this.hitung_total();
      this.$forceUpdate();
    },
    hitung_diskon(){
        let data= this.datanya;
        if(data.length>0){
            this.total=data.reduce((e,n)=>{
                let diskon = (parseInt(n.diskon)/100)*parseInt(n.harga)
                return e+((parseInt(n.harga)*parseInt(n.jumlah))-diskon)
            },0)
            this.diskons=true;
        }
        this.$forceUpdate();
    },
    hitung_total(){
        let satuan=this.satuans.filter(e=>e.id==this.vdata.satuan)
          if(satuan.length>0){
            satuan=parseInt(satuan[0].perkalian)
            this.vdata.total=(parseInt(this.vdata.harga)*satuan)*this.vdata.jumlah-((parseInt(this.vdata.diskon)/100)*parseInt(this.vdata.harga)*this.vdata.jumlah)
            this.$forceUpdate();
          }
    },
    deleteAll(){
        if(confirm('apakah yakin menghapus semua data?')){
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_keranjang",false).doc().select(`DELETE FROM app_kasir_keranjang WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                // console.log(res);
                this.getData();
            });
        }
    },
    deleteAll2(){
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_keranjang",false).doc().select(`DELETE FROM app_kasir_keranjang WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                // console.log(res);
                this.getData();
            });
    },
    getDatanya(){
      sdb.collection("app_kasir_keranjang",false).doc().select(`
      select tb1.id,tb1.id_user,tb1.nota,tb1.tanggal,tb1.id_pelanggan,tb3.nama,tb2.barcode,tb1.harga,tb1.jumlah,tb2.nama_produk,tb1.id_produk,tb1.diskon,tb1.total,tb1.tanggal_w
      from app_kasir_keranjang tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk left join app_kasir_pelanggan tb3 on tb3.id=tb1.id_pelanggan where tb1.nota='${this.vdata.nota}'`).then(res => {
        this.datanya=res;
        if(res.length>0){
            this.total=res.reduce((e,n)=>{
                return e+(parseInt(n.total))
            },0)
            let date = this.format(new Date()).split('-')[2]
            if(res[0].tanggal_w.split('-')[2]==date){
            }else{
                setTimeout(() => {
                    this.deleteAll2();
                }, 1000);
            }
            this.hitung_total();
        }
        this.$forceUpdate();
      });
    },
    getProduknya(){
      var type='1';
      // if(this.pilih=='dokter' || this.pilih=='nurse'){
      //   type='3'
      // }
      sdb.collection("app_kasir_produk",false).doc().get("*",` order by id`).then(res => {
          this.produks=res;
          this.produks.map(function (x){ // taruh setelah produks di initial
                return x.item_data = x.barcode + ' / ' + x.nama_produk;
          });
      });
    },
    getData(){
      this.getDatanya();
      this.getProduknya();
       sdb.collection("app_kasir_pelanggan",false).doc().get("*","order by id").then(res => {
          this.pelanggans=res;
          this.pelanggans.map(function (x){ // taruh setelah produks di initial
                  return x.item_data = x.nama + ' / ' + x.nohp;
          });
          this.$forceUpdate();
      });
      sdb.collection("app_kasir_transaksi_nota",false).doc().select(`
      select tb1.nota,tb1.id,tb1.tanggal,tb1.proses,tb2.id as id_pelanggan,tb2.nama as pelanggan,tb3.username as penginput,tb1.catatan_admin,tb1.catatan_dokter,tb1.catatan_apoteker,tb1.catatan_nurse,tb1.catatan_facial,tb1.createdAt,
      tb1.uang 
      from app_kasir_transaksi_nota tb1 left join app_kasir_pelanggan tb2 on tb2.id=tb1.id_pelanggan left join tbuser tb3 on tb3.id=tb1.id_user
      where tb1.status!='delete' AND tb1.tanggal_w='${this.format(this.pencarianHistory)}' order by id asc
      `).then(res => {
        this.transaksiNota=res;
        // console.log('transaksinota',res)
        this.$forceUpdate();
      });
    
      
      
    },
    ambil(data) {
      let proses=this.proses ;
      let catatan_admin=this.vdata.catatan_admin;
      let catatan_dokter=this.vdata.catatan_dokter;
      let catatan_nurse=this.vdata.catatan_nurse;
      let catatan_facial=this.vdata.catatan_facial;
      let catatan_apoteker=this.vdata.catatan_apoteker;
      this.vdata = data;
      this.vdata.catatan_admin=catatan_admin;
      this.vdata.catatan_dokter=catatan_dokter;
      this.vdata.catatan_nurse=catatan_nurse;
      this.vdata.catatan_facial=catatan_facial;
      this.vdata.catatan_apoteker=catatan_apoteker;
      this.cekstok();
      this.skip_ambil_diskon=true;
      // console.log(data)
      this.btn = "update";
      this.$forceUpdate();
    },
    async bayar(){
      let that=this
      if(this.uang==0){
        alert('Uang tidak boleh 0')
        if(!confirm('Apakah lanjutkan dengan uang 0 ? ')){
          return;
        }
      }else if(this.uang<this.total){
        alert('Uang tidak boleh kurang dari total harga')
        if(!confirm('Apakah lanjutkan dengan uang kurang dari total harga ? ')){
          return;
        }
      }
        if(confirm('Apakah yakin di proses ?')){
           let data = this.datanya;
            data=data.map(e=>{
                return e.id
            })
          let vdata={
            total:this.total,
            uang:this.uang,
            id_user:this.$store.state.users.id,
            id_pelanggan:this.datanya[0].id_pelanggan,
            tanggal_w:this.datanya[0].tanggal_w,
            cabang:this.$store.state.data.toko.cabang,
            keterangan:this.vdata.keterangan,
            nota:this.vdata.nota,
            tanggal:this.vdata.tanggal,
            status:'3',
            proses:'8',
            list_id:data.toString(),
          }
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_transaksi_nota",false).doc().select(`select * from app_kasir_transaksi_nota where nota='${this.vdata.nota}'`).then(async(res)=> {
let cttn =`
NOTA : ${that.vdata.nota} <br/>
TANGGAL : ${that.format3(new Date())} <br/>
============================ <br/>
Catatan Frontliner <br/>
${res[0].catatan_admin}  <br/>
============================ <br/>
Catatan Dokter <br/>
${res[0].catatan_dokter}  <br/>
============================ <br/>
Catatan Nurse <br/>
${res[0].catatan_nurse}  <br/>
============================ <br/>
Catatan Facial Therapist<br/>
${res[0].catatan_facial}  <br/>
============================ <br/>
Catatan Apoteker <br/>
${res[0].catatan_apoteker}  <br/>
============================ <br/>
`;
await sdb.collection("app_kasir_pelanggan_history",false).doc().select(`update app_kasir_pelanggan_history set 
catatan='${cttn}'
where id_transaksi_nota='${this.vdata.nota}'`)
              
              vdata.id=res[0].id;

              sdb2.collection('app_kasir_transaksi_nota').doc().set(vdata).then(res=>{
                sdb.collection("app_kasir_keranjang",false).doc().select(`update app_kasir_keranjang set posisi='nota' WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res=>{
                    // that.tambahPointPelanggan()
                    that.$router.push(`/admin/report/transaksi_now?nota=${that.vdata.nota}`)
                })
              })
            });
            
        }
    },
    async submit() {
      let that=this
      this.getTanggal()
      if(this.vdata.id_produk && parseInt(this.vdata.jumlah)>0 && this.vdata.id_pelanggan){
         let vdata={
           status:'keluar',
           tanggal_w:this.format(new Date(this.vdata.tanggal)),
           id_user:this.$store.state.users.id,
           posisi:'keranjang',
           beli:'kasir',
           cabang:this.$store.state.data.toko.cabang,
           diskon:this.vdata.diskon,
           harga:this.vdata.harga,
           id_pelanggan:this.vdata.id_pelanggan,
           id_produk:this.vdata.id_produk,
           jumlah:this.vdata.jumlah,
           tanggal:this.vdata.tanggal,
           nota:this.vdata.nota,
           total:this.vdata.total,
           satuan:this.vdata.satuan,
           catatan_admin:this.vdata.catatan_admin,
            catatan_dokter:this.vdata.catatan_dokter,
            catatan_nurse:this.vdata.catatan_nurse,
            catatan_facial:this.vdata.catatan_facial,
            catatan_apoteker:this.vdata.catatan_apoteker,
         }
          let cek = this.datanya.filter(e=>{
            if(e.id==that.vdata.id_produk){
              return e
            }
          })
          if (confirm("Apakah yakin proses dilanjutkan ?")) {
            if (this.btn == "tambah") {
              delete this.vdata.id;
             await sdb
                .collection("app_kasir_keranjang")
                .doc()
                .set(vdata)
                  that.getData();
                  that.cekstok();
                  let nota=that.vdata.nota;
                  let id_pelanggan=that.vdata.id_pelanggan
                  that.vdata= {
                    jumlah:0,
                    nota:nota,
                    id_pelanggan:id_pelanggan,
                    catatan_admin:vdata.catatan_admin,
                    catatan_dokter:vdata.catatan_dokter,
                    catatan_nurse:vdata.catatan_nurse,
                    catatan_facial:vdata.catatan_facial,
                    catatan_apoteker:vdata.catatan_apoteker,
                  }
                  that.getTanggal();
    
            } else if (this.btn == "update") {
              vdata.id=this.vdata.id
              await sdb
                .collection("app_kasir_keranjang")
                .doc()
                .set(vdata)
                  that.getData();
                  that.cekstok();
                   let nota=that.vdata.nota;
                  let id_pelanggan=that.vdata.id_pelanggan
                  that.vdata= {
                    jumlah:0,
                    nota:nota,
                    id_pelanggan:id_pelanggan,
                    catatan_admin:vdata.catatan_admin,
                    catatan_dokter:vdata.catatan_dokter,
                    catatan_nurse:vdata.catatan_nurse,
                    catatan_facial:vdata.catatan_facial,
                    catatan_apoteker:vdata.catatan_apoteker,
                  }
                  that.getTanggal();
            } else if (this.btn == "delete") {
              await sdb
                .collection("app_kasir_keranjang")
                .doc(this.vdata.id)
                .delete()
                  that.getData();
                  that.cekstok();
                    let nota=that.vdata.nota;
                  let id_pelanggan=that.vdata.id_pelanggan
                  that.vdata= {
                    jumlah:0,
                    nota:nota,
                    id_pelanggan:id_pelanggan,
                    catatan_admin:vdata.catatan_admin,
                    catatan_dokter:vdata.catatan_dokter,
                    catatan_nurse:vdata.catatan_nurse,
                    catatan_facial:vdata.catatan_facial,
                    catatan_apoteker:vdata.catatan_apoteker,
                  }
                  that.getTanggal();
            }
          }
      }else{
        sdb.alert('Isi Barcode, Jumlah, dan data pelanggan terlebih dahulu!')
      }
    },
    getTanggal(){
        this.vdata.tanggal=this.$datefns.format(new Date(),"YYYY-MM-DDTHH:mm")
    },
    getSatuan(){
      sdb.collection("app_kasir_produk_satuan",false).doc().get("*","order by id").then(res => {
          this.satuans=res;
          this.$forceUpdate();
      });
      
    }
  },
  async mounted() {
     this.vdata= {
          jumlah:0,
          catatan_admin:''
     }
    this.pencarianHistory=this.format(new Date());
    this.pilih=this.$store.state.users.level
    let that=this;
    //  rdb.ref(`/scan/${that.$store.state.users.id}/barcode`).on('value',(e)=>{ //read
    //       if(that.produks.length>0){
    //         let data =that.produks.filter(k=>{
    //           if(k.barcode==e.val().id){
    //             return k
    //           }
    //         })
    //         that.vdata.id_produk=data[0].id
    //         that.cekstok();
    //         that.$forceUpdate();
    //       }
    // }) 
    // ==========================
    // AUTO REFRESH TICKET 
    // rdb.ref(`/ticket/admin`).on('value',(e)=>{ //read
    //     that.getData();
    // }) 
      setTimeout(() => {
        // this.getNota();
        this.getData();
        this.getSatuan();
    }, 1000);
    this.getTanggal();
    this.today=this.formatNota(new Date());
      // document.addEventListener('keydown',function(e){
      //   if(e.key=='+' || e.key=='='){
      //     that.tambah()
      //   }else if(e.key=='-'){
      //     that.kurang()
      //   }
      //   if(e.key=='F1'){
      //     e.preventDefault()
      //     that.$el.querySelector('.vs__search').focus();
      //   }else if(e.key=='F2'){
      //     e.preventDefault();
      //     that.kurang()
      //   }else if(e.key=='F3'){
      //     e.preventDefault();
      //     that.tambah()
      //   }else if(e.key=='F4'){
      //     e.preventDefault();
      //     that.$el.querySelector('#selectpelanggan').focus();
      //   }else if(e.key=='F5'){
      //     e.preventDefault();
      //     that.$el.querySelector('#btnsubmit').click();
      //   }else if(e.key=='F6'){
      //     e.preventDefault();
      //     that.$el.querySelector('#btnbayar').click();
      //     setTimeout(() => {
      //       that.$el.querySelector('#uang').focus();
      //     }, 2000);
      //   }else if(e.key=='F7'){
      //     e.preventDefault();
      //     that.$el.querySelector('#keterangan').focus();
      //   }else if(e.key=='F8'){
      //     e.preventDefault();
      //     that.$el.querySelector('#btnbayar2').click();
      //   }
      // })
        // const slider = document.querySelector('.items');
        // let isDown = false;
        // let startX;
        // let scrollLeft;
        // slider.addEventListener('mousedown', (e) => {
        //     isDown = true;
        //     slider.classList.add('active');
        //     startX = e.pageX - slider.offsetLeft;
        //     scrollLeft = slider.scrollLeft;
        // });
        // slider.addEventListener('mouseleave', () => {
        //     isDown = false;
        //     slider.classList.remove('active');
        // });
        // slider.addEventListener('mouseup', () => {
        //     isDown = false;
        //     slider.classList.remove('active');
        // });
        // slider.addEventListener('mousemove', (e) => {
        //     if(!isDown) return;
        //     e.preventDefault();
        //     const x = e.pageX - slider.offsetLeft;
        //     const walk = (x - startX) * 3; //scroll-fast
        //     slider.scrollLeft = scrollLeft - walk;
        // });
  },
};
</script>